import styled from 'styled-components'

const Tag = styled.div`
  font-size: 12px;
  background-color: #7a6e5f;
  border: none;
  color: white;
  padding: 4px 6px;
  border-radius: 3px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: #1d396b;
  }
`

const PartyProperties = ({ properties }) => {
  return (
    <div style={{ display: 'flex', marginBottom: 10 }}>
      {properties.map((property) => {
        return (
          <Tag>
            {property.name}
            {property.type === 'bool' ? '' : `: ${property.string}`}
          </Tag>
        )
      })}
    </div>
  )
}

export default PartyProperties
