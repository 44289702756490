import http from './http'

export const getJobs = (query) => {
  return http.get('/api/jobs', query)
}

export const createJobs = (body) => {
  return http.blob('/api/jobs', body)
}

export const updateJobs = (id, body) => {
  return http.blob(`/api/jobs`, body, { job: id }, 'PUT')
}

export const getJobGroups = (jobs) => {
  const groups = {}
  if (!jobs) return groups
  jobs?.forEach((job) => {
    if (!groups[job.name]) groups[job.name] = []
    groups[job.name].push(job)
  })
  return groups
}
