import http from './http'

export const getReviews = (query) => {
  return http.get('/api/reviews', query)
}

export const createReviews = (body) => {
  return http.post('/api/reviews', body)
}

export const updateReviews = (body) => {
  return http.put('/api/reviews', body)
}
