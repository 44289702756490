import { Formik, Form, Field, ErrorMessage } from 'formik'

import http from '../../services/http'
import { setJWT, setUser } from '../../services/users'

import './style.css'

const LoginForm = () => {
  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      validate={(values) => {
        const errors = {}
        Object.keys(values).forEach((key) => {
          if (!values[key]) errors[key] = 'Required'
        })
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        http.post(`/api/login`, values).then((res) => {
          setSubmitting(false)
          if (res.error) return alert(res.error)
          setJWT(res.jwt)
          setUser(res.user)
          window.location = '/'
        })
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div className='input-row login-input'>
            <div className='input-label'>Username</div>
            <Field type='username' name='username' />
            <ErrorMessage name='username' component='div' className='error' />
          </div>
          <div className='input-row login-input'>
            <div className='input-label'>Password</div>
            <Field type='password' name='password' />
            <ErrorMessage name='password' component='div' className='error' />
          </div>
          <div className='login-btn-bar'>
            <button className='btn ' type='submit' disabled={isSubmitting}>
              Log In
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default LoginForm
