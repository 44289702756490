import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'

import GameContext from '../../contexts/GameContext'
import UserContext from '../../contexts/UserContext'
import { getTasks } from '../../services/tasks'
import { createParties } from '../../services/parties'
import {
  setJWT,
  setUser,
  register,
  validateUsername,
} from '../../services/users'

import Helmet from '../../components/Helmet'
import Button from '../../components/Button'
import JobSelect from '../../components/Jobs/JobSelect'
import CreateProperties from '../../components/Properties/CreateProperties'
import { StyledSelect } from '../../components/Styled'

const CreateParty = () => {
  const [tasks, setTasks] = useState([])
  const [username, setUsername] = useState('')
  const [task, setTask] = useState('')
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [jobs, setJobs] = useState([])
  const [properties, setProperties] = useState({})

  const { user, setUser: setLocalUser } = useContext(UserContext)
  const { game } = useContext(GameContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (game.has('PARTY:TYPES'))
      getTasks().then((res) => {
        setTasks(res.data)
      })
  }, [])

  const createParty = async () => {
    const payload = {
      task: task.value,
      name: title,
      description,
      jobs: jobs.map((job) => job.id),
      properties,
    }
    if (game.has('PARTY:TYPES') && !task)
      return alert('A party type is required')
    if (user) {
      payload.user = user.id
    } else {
      if (!validateUsername(username)) return alert('Invalid username')

      const userRes = await register({ username })
      if (userRes.error) return alert(userRes.error)
      setJWT(userRes.jwt)
      setUser(userRes.user)
      setLocalUser(userRes.user)
      payload.user = userRes.user.id
    }

    const res = await createParties(payload)
    navigate(`/party/${res.data.id}`)
  }

  return (
    <div className='page container-fluid create-party'>
      <Helmet />
      <h1>Host a Party</h1>
      {!user && (
        <div className='input-row'>
          <div className='input-label'>Username</div>
          <div>
            <input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value)
              }}
            />
          </div>
        </div>
      )}
      {game.has('PARTY:TYPES') && (
        <div className='input-row'>
          <div className='input-label'>Party Type</div>
          <StyledSelect
            value={task}
            options={tasks.map((task) => {
              return { value: task.id, label: task.name }
            })}
            onChange={(option) => {
              setTask(option)
            }}
            className='comp-create-property'
          />
        </div>
      )}
      <div className='input-row'>
        <div className='input-label'>Title</div>
        <div>
          <input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
          />
        </div>
      </div>
      <div className='input-row'>
        <div className='input-label'>Description</div>
        <div>
          <textarea
            value={description}
            onChange={(e) => {
              setDescription(e.target.value)
            }}
          />
        </div>
      </div>
      {game.has('PARTY:CUSTOM') && (
        <div className='input-row'>
          <div className='input-label'>Roles</div>
          <JobSelect
            value=''
            onChange={(job) => {
              if (game.size && jobs.length === game.size)
                return alert("You've reached the max party size")
              jobs.push(job.data)
              setJobs([...jobs])
            }}
          />
          <div>
            {jobs.map((job, idx) => {
              return (
                <div className='party-create-job' key={idx}>
                  {job.name}
                  <button
                    className='btn-icon party-create-delete'
                    onClick={() => {
                      jobs.splice(idx, 1)
                      setJobs([...jobs])
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <CreateProperties value={properties} onChange={setProperties} />
      <div className='input-row'>
        <Button className='btn' onClick={createParty}>
          Host Party
        </Button>
      </div>
    </div>
  )
}

export default CreateParty
