import { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { can } from 'rbac'

import GameContext, { GameContextProvider } from './contexts/GameContext'
import UserContext, { UserContextProvider } from './contexts/UserContext'
import { SocketProvider } from './contexts/SocketContext'

import Footer from './components/Footer'
import Navbar from './components/Navbar'

import Party from './pages/Party'
import CreateParty from './pages/Party/CreateParty'
import Home from './pages/Home'
import Login from './pages/Login'
import Jobs from './pages/Jobs'
import Tasks from './pages/Tasks'
import Profile from './pages/Profile'
import Parties from './pages/Parties'
import Signup from './pages/Login/Signup'

// import AdSlot from './components/Ads/AdSlot'

import { ThemeProvider } from 'styled-components'
import { darkTheme, GlobalStyles } from './theme'

import 'flexboxgrid/css/flexboxgrid.min.css'
import './App.css'

function App() {
  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <UserContextProvider>
          <GameContextProvider>
            <SocketProvider>
              <BrowserRouter>
                <GlobalStyles />
                {/* <Navbar toggleTheme={toggleTheme} theme={theme} /> */}
                <Navbar />
                <div className='App'>
                  <UserContext.Consumer>
                    {({ user }) => {
                      return (
                        <Routes>
                          {/* {can(user, 'COMPS:APPROVE') && (
                              <Route path='admin' element={<Admin />} />
                            )} */}
                          <Route path='login' element={<Login />} />
                          <Route path='jobs' element={<Jobs />} />
                          <Route path='tasks' element={<Tasks />} />
                          <Route path='parties' element={<Parties />} />
                          <Route path='party/:id' element={<Party />} />
                          <Route path='party/host' element={<CreateParty />} />
                          <Route path='profile/:id/*' element={<Profile />} />
                          <Route path='signup' element={<Signup />} />
                          <Route index element={<Home />} />
                        </Routes>
                      )
                    }}
                  </UserContext.Consumer>
                </div>
                <Footer />
                {/* <AdSlot name='anchor' /> */}
              </BrowserRouter>
            </SocketProvider>
          </GameContextProvider>
        </UserContextProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
