import React from 'react'
import { FaStar, FaRegStar, FaStarHalfAlt } from 'react-icons/fa'
import './style.css'

const RatingStars = ({ rating, setRating, size }) => {
  const starStyle = {
    fontSize: size ? size : setRating ? 30 : 15,
    cursor: setRating ? 'pointer' : '',
  }

  const stars = []
  for (let i = 1; i <= 5; i++) {
    if (i <= rating || rating > i - 0.5) {
      stars.push(
        <FaStar
          key={i}
          onClick={() => {
            if (setRating) setRating(i)
          }}
          className='review-star'
          style={starStyle}
        />
      )
    } else if (rating <= i - 0.5 && rating > i - 1) {
      stars.push(
        <FaStarHalfAlt key={i} className='review-star' style={starStyle} />
      )
    } else {
      stars.push(
        <FaRegStar
          key={i}
          onClick={() => {
            if (setRating) setRating(i)
          }}
          className='review-star'
          style={starStyle}
        />
      )
    }
  }

  return stars
}

export default RatingStars
