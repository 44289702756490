export default {
  body: '#020202',
  navbar: '#323232',
  btnColor: '#272624',
  btnHoverColor: '#2b2b29',
  btnBorder: '2px solid #373433',
  btnBorderRadius: '2px',
  btnAltColor: '#b97b2f',
  primary: '#b97b2f',
  cardColor: '#323232',
  cardBorder: '2px solid #020202',
  cardRadius: '2px',
  cardLabelColor: '#1f1e1e',
  cardLabelBorder: '2px #020202 solid',
  cardLabelRadius: '2px',
}
