import { useState, useContext, useEffect } from 'react'
import { useParams, Route, Routes, Link } from 'react-router-dom'

import { getParties } from '../../services/parties'
import { getUsers } from '../../services/users'
import UserContext from '../../contexts/UserContext'

import Parties from '../../components/Parties'
import ReviewsC from '../../components/Reviews'
import Helmet from '../../components/Helmet'
import { StyledLink } from '../../components/Styled'

import Reviews from './Reviews'
import Settings from './Settings'
import RatingStars from '../../components/Reviews/RatingStars'

import './style.css'

const ProfileMain = ({ user, isSelf }) => {
  const [loading, setLoading] = useState(true)
  const [parties, setParties] = useState([])

  useEffect(() => {
    setLoading(true)
    setParties([])
    const query = { user: user.id, jobs: true }
    if (!isSelf) query.active = true
    getParties(query).then((res) => {
      if (res.data) setParties(res.data)
      setLoading(false)
    })
  }, [user.id])

  return (
    <>
      <div className='profile-user-reviews'>
        <RatingStars rating={user.rating} size={20} /> ({user.reviews})
      </div>
      <h3>Parties</h3>
      <Parties parties={parties} loading={loading} />
      <ReviewsC user={user} isSelf={isSelf} limit={3} />
      <Link to={`/profile/${user.id}/reviews`} className='view-all'>
        VIEW ALL
      </Link>
      {isSelf && (
        <StyledLink className='btn' to='settings'>
          Settings
        </StyledLink>
      )}
    </>
  )
}

const Profile = () => {
  const { user: currUser } = useContext(UserContext)
  const [user, setUser] = useState({})
  const params = useParams()

  useEffect(() => {
    setUser({})
    getUsers({ id: params.id }).then((res) => {
      if (res.error) return
      setUser(res.data)
    })
  }, [params.id])

  const isSelf = user.id === currUser.id

  return (
    <div className='page container-fluid'>
      <Helmet data={{ username: user.username }} />
      <h1>{user.username}</h1>
      {user.id && (
        <Routes>
          <Route index element={<ProfileMain user={user} isSelf={isSelf} />} />
          <Route
            path='reviews/*'
            element={<Reviews user={user} isSelf={isSelf} />}
          />
          {isSelf && <Route path='settings' element={<Settings />} />}
        </Routes>
      )}
    </div>
  )
}

export default Profile
