import { useContext } from 'react'
import { CDN_URL } from '../../services/constants'
import styled from 'styled-components'

import './style.css'

import GameContext from '../../contexts/GameContext'
import Helmet from '../../components/Helmet'
import { StyledLink } from '../../components/Styled'

const HomeTitle = styled.h1`
  color: ${({ theme }) => theme.primary};
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 15px;
`

const Home = () => {
  const { game } = useContext(GameContext)

  return (
    <div className='page container-fluid home-container'>
      <Helmet />
      <div className='home'>
        {game.schema === 'public' && (
          <div className='home-logo'>
            <img
              src={`${CDN_URL}/${game.bucket}/logo.png`}
              alt={`${game.name} party`}
            />
          </div>
        )}
        <HomeTitle>{game.name} Party Finder</HomeTitle>
        <div>
          <StyledLink
            className='btn'
            to='/party/host'
            style={{ marginRight: 5 }}
          >
            Host Party
          </StyledLink>
          <StyledLink className='btn' to='/parties' style={{ marginLeft: 5 }}>
            Join Party
          </StyledLink>
        </div>
      </div>
    </div>
  )
}

export default Home
