import { useContext, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'

import UserContext from '../../contexts/UserContext'
import {
  CAPTCHA_KEY,
  updateUser,
  changePassword,
  setUser,
  validateUsername,
  verifyPassword,
} from '../../services/users'

const Settings = () => {
  const { user, setUser: setLocalUser } = useContext(UserContext)

  return (
    <div className='profile-settings'>
      <div className='input-row'>
        <h2>Change Username</h2>
        <Formik
          initialValues={{ username: '' }}
          validate={(values) => {
            const errors = {}
            if (!values.username) errors.username = 'Required'
            if (values.username && !validateUsername(values.username))
              errors.username = 'Invalid username'
            return errors
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            resetForm()
            updateUser(values).then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
              const newUser = { ...user, username: res.user.username }
              setUser(newUser)
              setLocalUser(newUser)
              alert('You have successfully updated your username')
            })
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row'>
                <div className='input-label'>
                  Username<span className='error'>*</span>
                </div>
                <Field type='text' name='username' />
                <ErrorMessage
                  name='username'
                  component='div'
                  className='error'
                />
              </div>
              <div className='login-btn-bar' style={{ marginTop: 10 }}>
                <button className='btn' type='submit' disabled={isSubmitting}>
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className='input-row'>
        <h2>Change Password</h2>
        {user.generated && (
          <div className='input-row'>
            To claim your account and use it on other devices set a password
            below
          </div>
        )}
        <Formik
          initialValues={{
            password: '',
            password2: '',
          }}
          validate={(values) => {
            const errors = {}
            if (!values.password) errors.password = 'Required'
            if (!values.password2) errors.password2 = 'Required'
            if (values.password2 !== values.password)
              errors.password2 = 'Passwords do not match'
            if (
              process.env.NODE_ENV !== 'development' &&
              values.password &&
              !verifyPassword(values.password)
            ) {
              errors.password =
                'Your password must be at least 8 characters and contain at least 1 capital letter, 1 small letter, and 1 number'
            }
            return errors
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            resetForm()
            changePassword(values).then((res) => {
              setSubmitting(false)
              if (res.error) return alert(res.error)
              const newUser = { ...user, generated: false }
              setUser(newUser)
              setLocalUser(newUser)
              alert('You have successfully updated your password')
            })
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <div className='input-row'>
                <div className='input-label'>
                  Password<span className='error'>*</span>
                </div>
                <Field type='password' name='password' />
                <ErrorMessage
                  name='password'
                  component='div'
                  className='error'
                />
              </div>
              <div className='input-row'>
                <div className='input-label'>
                  Confirm password<span className='error'>*</span>
                </div>
                <Field type='password' name='password2' />
                <ErrorMessage
                  name='password2'
                  component='div'
                  className='error'
                />
              </div>
              <div className='login-btn-bar' style={{ marginTop: 10 }}>
                <button className='btn' type='submit' disabled={isSubmitting}>
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default Settings
