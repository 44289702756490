import React, { createContext } from 'react'
import socketio from 'socket.io-client'

const SOCKET_URL =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_GAME === 'palia'
      ? 'https://paliaparty-f646f206a87a.herokuapp.com'
      : 'https://darkanddarkerparty-f1df6516274f.herokuapp.com'
    : 'http://localhost:9000'
const SocketContext = createContext()
const socket = socketio.connect(SOCKET_URL, {
  transports: ['websocket', 'polling'],
  reconnectionAttempts: 5,
})

export function SocketProvider({ children }) {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}

export default SocketContext
