import React, { createContext, useState } from 'react'
import { getUser } from '../services/users'
// import http from '../services/http'

const UserContext = createContext()

export function UserContextProvider({ children }) {
  const currUser = getUser()
  const [user, setUser] = useState(currUser)

  // useEffect(() => {
  //   if (currUser) {
  //     http.get('/user', {}).then((res) => {
  //       if (res.error) return alert(res.error)

  //       if (res.user) {
  //         setUser(res.user)
  //         setLocalUser(res.user)
  //       }
  //     })
  //   }
  // }, [])

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
