import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useNavigate } from 'react-router-dom'

import { createReviews } from '../../services/reviews'

import RatingStars from './RatingStars'

const CreateReview = ({ user }) => {
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={{ rating: '', description: '' }}
      validate={(values) => {
        const errors = {}
        Object.keys(values).forEach((key) => {
          if (!values[key]) errors[key] = 'Required'
        })
        return errors
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        createReviews({ ...values, user: user.id }).then((res) => {
          setSubmitting(false)
          if (res.error) return alert(res.error)
          navigate(`/profile/${user.id}`)
        })
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <h1>Leave a Review</h1>
          <div className='input-row'>
            <div className='input-label'>Rating</div>
            <RatingStars
              rating={values.rating}
              setRating={(rating) => {
                setFieldValue('rating', rating)
              }}
            />
          </div>
          <div className='input-row'>
            <div className='input-label'>Description</div>
            <Field name='description' as='textarea' />
            <ErrorMessage
              name='description'
              component='div'
              className='error'
            />
          </div>
          <div className='login-btn-bar'>
            <button className='btn ' type='submit' disabled={isSubmitting}>
              Leave Review
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default CreateReview
