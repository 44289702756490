import { useState, useEffect } from 'react'

import { getProperties } from '../../services/properties'

import { StyledSelect } from '../Styled'

const CreateProperties = ({ value, onChange }) => {
  const [properties, setProperties] = useState([])

  useEffect(() => {
    getProperties().then((res) => {
      setProperties(res.data)
    })
  }, [])

  return (
    <div>
      {properties.map((property) => {
        switch (property.type) {
          case 'bool':
            return (
              <div className='input-row'>
                <div className='input-label input-checkbox'>
                  <input
                    type='checkbox'
                    onChange={(e) => {
                      onChange({ ...value, [property.id]: e.target.checked })
                    }}
                  />
                  <div>{property.name}</div>
                </div>
              </div>
            )
          default:
            return (
              <div className='input-row'>
                <div className='input-label'>{property.name}</div>
                <StyledSelect
                  onChange={(option) => {
                    onChange({ ...value, [property.id]: option.value })
                  }}
                  options={property.options?.map((option) => {
                    return {
                      label: option.label || option.value,
                      value: option.value,
                    }
                  })}
                />
              </div>
            )
        }
      })}
    </div>
  )
}

export default CreateProperties
