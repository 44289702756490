import http from './http'

export const getTasks = (query) => {
  return http.get('/api/tasks', query)
}

export const createTasks = (body) => {
  return http.post('/api/tasks', body)
}

export const updateTasks = (id, body) => {
  return http.blob('/api/tasks', body, { task: id }, 'PUT')
}
