import { useState, useEffect, useContext } from 'react'
import { can } from 'rbac'

import UserContext from '../../contexts/UserContext'
import { getTasks } from '../../services/tasks'

import Helmet from '../../components/Helmet'
import Task from '../../components/Tasks/Task'
import CreateTask from './CreateTask'

import './style.css'

const Tasks = () => {
  const [tasks, setTasks] = useState([])
  const { user } = useContext(UserContext)

  useEffect(() => {
    getTasks().then((res) => {
      setTasks(res.data)
    })
  }, [])

  return (
    <div className='page container-fluid'>
      <Helmet />
      {user && can(user, 'ADMIN:PANEL') && <CreateTask />}
      {tasks.map((task) => {
        return <Task task={task} />
      })}
    </div>
  )
}

export default Tasks
