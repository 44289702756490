import { useState, useEffect } from 'react'

import { getReviews } from '../../services/reviews'

import RatingStars from './RatingStars'
import { StyledLink } from '../Styled'

const Reviews = ({ user, isSelf, limit }) => {
  const [loading, setLoading] = useState(true)
  const [reviews, setReviews] = useState([])
  useEffect(() => {
    setLoading(true)
    getReviews({ user: user.id, limit }).then((res) => {
      if (res.error) return
      setReviews(res.data)
      setLoading(false)
    })
  }, [user.id])

  return (
    <div>
      <div className='review-header'>
        <h2>Reviews</h2>
      </div>
      {!isSelf && (
        <StyledLink to={`/profile/${user.id}/reviews/create`} className='btn leave-review'>
          Leave a Review
        </StyledLink>
      )}
      <div className='reviews'>
        {!loading && reviews.length === 0 && <p>No reviews</p>}
        {reviews.map((review) => {
          const { reviewer } = review
          return (
            <div className='review'>
              <StyledLink
                to={`/profile/${reviewer.id}`}
                className='review-user'
              >
                {reviewer.username}
              </StyledLink>
              <div>
                <RatingStars rating={review.rating} />
              </div>
              <p>{review.description}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Reviews
