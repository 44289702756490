import { useState, useEffect, useContext } from 'react'

import { getJobs, createJobs, updateJobs } from '../../services/jobs'
import { CDN_URL } from '../../services/constants'

import Button from '../../components/Button'
import FileUpload from '../../components/FileUpload'

import './style.css'

const Job = ({ job }) => {
  const [img, setImg] = useState({})

  return (
    <div className='job-create-job'>
      <div className='input-row job-img-up'>
        <FileUpload
          message={
            job.img ? (
              <img src={`${CDN_URL}/${job.img}`} alt='upload' />
            ) : img?.url ? (
              <img src={img.url} alt='upload' />
            ) : (
              ''
            )
          }
          handleUpload={(image, blob, fileName) => {
            if (image) {
              setImg({ url: image, blob, fileName })
              const fd = new FormData()
              fd.append('image', blob, fileName)
              fd.append('body', JSON.stringify({}))
              updateJobs(job.id, fd)
            }
          }}
          noWarning
        />
      </div>
      <div>{job.name}</div>
    </div>
  )
}

const Jobs = () => {
  const [jobs, setJobs] = useState([])
  const [newJob, setNewJob] = useState('')
  const [img, setImg] = useState({})

  useEffect(() => {
    getJobs().then((res) => {
      setJobs(res.data)
    })
  }, [])

  return (
    <div className='page container-fluid'>
      <div>
        <div className='input-row'>
          <div className='input-label'>New Job</div>
          <div className='input-row job-img-up'>
            <FileUpload
              message={img?.url ? <img src={img.url} alt='upload' /> : ''}
              handleUpload={(image, blob, fileName) => {
                if (image) {
                  setImg({ url: image, blob, fileName })
                }
              }}
            />
          </div>
          <div className='input-row'>
            <div className='input-label'>Job Name</div>
            <div>
              <input
                placeholder='Job name'
                value={newJob}
                onChange={(e) => {
                  setNewJob(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <div className='input-row'>
          <Button
            onClick={() => {
              const fd = new FormData()
              if (img.blob) fd.append('image', img.blob, img.fileName)
              fd.append('body', JSON.stringify({ name: newJob }))
              createJobs(fd).then((res) => {
                if (res.error) return alert(res.error)
                setNewJob('')
                setImg({})
              })
            }}
          >
            Create
          </Button>
        </div>
      </div>
      {jobs.map((job) => {
        return <Job job={job} />
      })}
    </div>
  )
}

export default Jobs
