import { createContext, useState } from 'react'
import games from 'games'

const GameContext = createContext()

export function GameContextProvider({ children }) {
  // const [game, setGame] = useState(games[process.env.REACT_APP_GAME])
  return (
    <GameContext.Provider value={{ game: games[process.env.REACT_APP_GAME] }}>
      {children}
    </GameContext.Provider>
  )
}

export default GameContext
