import qs from 'qs'

import { getJWT } from './users'

export const useQuery = (search) => {
  return new URLSearchParams(search)
}

export const getLang = () => {
  let lang = localStorage.getItem('defaultLanguage')
  if (lang) return lang
  return 'en-US'
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Accept-Language': getLang(),
}

const http = {
  get: (url, params, options, noGame) => {
    let currHeaders = { ...headers }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt

    return fetch(`${url}?${qs.stringify(params)}`, {
      headers: currHeaders,
      credentials: 'include',
      ...(options || {}),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response
        default:
          return response.json().then((res) => {
            try {
              let currVer = localStorage.getItem('version')
              if (res && res.version && currVer !== res.version) {
                localStorage.setItem('version', res.version)
                window.location.reload(true)
              }
            } catch {
              localStorage.setItem('version', res.version)
            }
            return res
          })
      }
    })
  },
  post: (url, data) => {
    let currHeaders = { ...headers }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt

    return fetch(`${url}`, {
      headers: currHeaders,
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response
        default:
          return response.json()
      }
    })
  },
  put: (url, data) => {
    let currHeaders = { ...headers }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt

    return fetch(`${url}`, {
      headers: currHeaders,
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify(data),
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response
        default:
          return response.json()
      }
    })
  },
  del: (url, params) => {
    let currHeaders = { ...headers }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt

    return fetch(`${url}?${qs.stringify(params)}`, {
      headers: currHeaders,
      method: 'DELETE',
      credentials: 'include',
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response
        default:
          return response.json()
      }
    })
  },
  image: (url, data, type) => {
    let currHeaders = {
      'Content-Type': type,
    }
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt
    return fetch(`${url}`, {
      headers: currHeaders,
      method: 'PUT',
      body: data,
    }).then((response) => response)
  },
  blob: (url, data, params, method) => {
    let currHeaders = {}
    let jwt = getJWT()
    if (jwt) currHeaders.Authorization = jwt

    return fetch(`${url}?${qs.stringify(params)}`, {
      headers: currHeaders,
      method: method || 'POST',
      body: data,
    }).then((response) => {
      switch (response.status) {
        case 403:
          return response
        default:
          return response.json()
      }
    })
  },
}

export default http
