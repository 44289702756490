import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

import { getParties } from '../../services/parties'

import Helmet from '../../components/Helmet'
import Parties from '../../components/Parties'

import PartiesFilter from './PartiesFilter'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [parties, setParties] = useState([])
  const location = useLocation()
  const query = qs.parse(location.search.substring(1))

  useEffect(() => {
    setParties([])
    getParties({
      active: true,
      jobs: true,
      properties: true,
      job: query.role,
      ...query,
    }).then((res) => {
      setParties(res.data)
      setLoading(false)
    })
  }, [location.search])

  return (
    <div className='page container-fluid'>
      <Helmet />
      <PartiesFilter />
      <Parties loading={loading} parties={parties} />
    </div>
  )
}

export default Home
