import { useState, useContext } from 'react'
import { can } from 'rbac'

import UserContext from '../../contexts/UserContext'
import { updateTasks } from '../../services/tasks'
import { getJobGroups } from '../../services/jobs'
import { CDN_URL } from '../../services/constants'

import FileUpload from '../../components/FileUpload'

import './style.css'

const Task = ({ task }) => {
  const { user } = useContext(UserContext)
  const [img, setImg] = useState({})

  const groups = getJobGroups(task.jobs)

  return (
    <div className='party-card task'>
      {user && can(user, 'ADMIN:PANEL') ? (
        <div className='task-img'>
          <FileUpload
            message={
              task.img ? (
                <img src={`${CDN_URL}/${task.img}`} alt='upload' />
              ) : img?.url ? (
                <img src={img.url} alt='upload' />
              ) : (
                ''
              )
            }
            handleUpload={(image, blob, fileName) => {
              if (image) {
                setImg({ url: image, blob, fileName })
                const fd = new FormData()
                fd.append('image', blob, fileName)
                fd.append('body', JSON.stringify({}))
                updateTasks(task.id, fd)
              }
            }}
            noWarning
          />
        </div>
      ) : (
        <div className='task-imgs'>
          <img src={`${CDN_URL}/${task.img}`} alt={task.name} />
        </div>
      )}
      <div>
        <div className='task-name'>{task.name}</div>
        <div className='task-jobs'>
          {Object.keys(groups).map((jobName) => {
            return (
              <div>
                {jobName} ({groups[jobName].length})
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Task
