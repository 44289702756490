import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import pagemeta from 'pagemeta'

import GameContext from '../contexts/GameContext'

const ServerHelmet = ({ data }) => {
  data = data || {}

  const { game } = useContext(GameContext)
  const location = useLocation()
  const config = pagemeta.get(location.pathname, {
    ...data,
    game: game.name,
    site: game.site,
  })

  if (!config) return null
  const { title, description } = config
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
    </Helmet>
  )
}

export default ServerHelmet
