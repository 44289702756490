import { DateTime } from 'luxon'
import styled from 'styled-components'

import { StyledLink } from '../Styled'
import PaliaDot from '../PaliaDot'

import PartyProperties from './PartyProperties'

import './style.css'

export const PartyCard = styled.div`
  background-color: ${({ theme }) => theme.cardColor};
  border-radius: ${({ theme }) => theme.cardRadius};
  border: ${({ theme }) => theme.cardBorder};
  color: ${({ theme }) => theme.cardTextColor};
  margin-bottom: 20px;
  margin-top: 30px;
  padding: 40px 10px 5px 10px;
  position: relative;

  &:before {
    background: ${({ theme }) => theme.cardColor};
    border-radius: ${({ theme }) => theme.cardRadius};
    bottom: -5px;
    content: '';
    left: -5px;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: -1;
  }
`

export const PartyUser = styled.div`
  background-color: ${({ theme }) => theme.cardLabelColor};
  border-radius: ${({ theme }) => theme.cardLabelRadius};
  border: ${({ theme }) => theme.cardLabelBorder};
  color: #fffcf7;
  font-weight: bold;
  left: 50%;
  padding: 10px 5px;
  position: absolute;
  top: -27px;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
`

const Parties = ({ loading, parties }) => {
  return (
    <div>
      {!loading && parties.length === 0 && (
        <div>
          No active parties.{' '}
          <StyledLink className='btn' to='/party/host'>
            Host
          </StyledLink>{' '}
          your own
        </div>
      )}
      <div className='row'>
        {parties.map((party) => {
          let size = 0
          party.jobs?.forEach((job) => {
            if (job.username) size++
          })
          {
            /* const posted = DateTime.fromISO(party.created_at).toRelative() */
          }

          return (
            <div className='col-xs-12 col-md-3'>
              <StyledLink to={`/party/${party.id}`}>
                <PartyCard>
                  <PartyUser>
                    <PaliaDot />
                    {party.username}
                    <PaliaDot />
                  </PartyUser>
                  <div className='party-top'>
                    <div className='party-name'>
                      {party.name || party.task}&nbsp;
                    </div>
                    {party.task && (
                      <div className='party-type'>{party.task}</div>
                    )}
                    {party.properties && (
                      <PartyProperties properties={party.properties} />
                    )}
                    {/* <div className='party-des'>{party.description}&nbsp;</div> */}
                  </div>
                  <div className='party-bottom'>
                    <PaliaDot />
                    <div className='party-size'>
                      {size}/{party.jobs?.length} Spots
                    </div>
                    <PaliaDot />
                  </div>
                </PartyCard>
              </StyledLink>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Parties
