import { useEffect, useState, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { FaDiscord } from 'react-icons/fa'
import qs from 'qs'

import http from '../../services/http'
import { getUser, setUser, setJWT } from '../../services/users'
import Loading from '../../components/Loading'

import LoginForm from './LoginForm'
import { discordUrl } from './Signup'

import './style.css'

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [creating, setCreating] = useState(false)
  const query = qs.parse(location.search.substring(1))
  const user = getUser()

  useEffect(() => {
    const { code } = query
    if (code) {
      setCreating(true)
      http
        .post(`/api/register`, { currUserId: user ? user.id : undefined, code })
        .then((res) => {
          if (res.error) {
            setCreating(false)
            return alert(res.error)
          }
          if (res.link) return navigate.push(`/profile/${user.id}`)
          setJWT(res.jwt)
          setUser(res.user)
          window.location = '/'
        })
    }
  }, [])

  if (creating) return <Loading />

  return (
    <div className='login'>
      <Helmet>
        <title>Log In | Palia Party</title>
        <meta name='description' content={`Login Diamond Hands`} />
      </Helmet>
      <div className='container'>
        <h2>Log In</h2>
        <a href={discordUrl} className='discord-login'>
          <FaDiscord style={{ marginRight: 5 }} />
          Log In with Discord
        </a>
        <div className='or'>
          <div className='or-line'></div>
          <span>or</span>
          <div className='or-line'></div>
        </div>
        <LoginForm />
        <div className='login-switch-container'>
          Don't have an account?{' '}
          <Link to='/signup' style={{ color: 'unset' }}>
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Login
