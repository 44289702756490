import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'

export const StyledInput = styled.input`
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.text};
  font-size: 14px;
`

export const StyledTextArea = styled.textarea`
  background-color: ${({ theme }) => theme.cardBackground};
  color: ${({ theme }) => theme.text};
  font-size: 14px;
`

export const TableHeader = styled.th`
  background-color: ${({ theme }) => theme.body};
  border: ${({ theme }) => theme.tableBorder};
`

export const TableCell = styled.td`
  padding: 5px;
  border: ${({ theme }) => theme.tableBorder};
  height: 1px;
`

export const NewTableHeader = styled.th``

export const TableRow = styled.tr`
  background-color: ${({ theme }) => theme.body};

  td:first-child {
    border-top-left-radius: 6px;
  }
  td:last-child {
    border-top-right-radius: 6px;
  }
  td:first-child {
    border-bottom-left-radius: 6px;
  }
  td:last-child {
    border-bottom-right-radius: 6px;
  }
`

export const NewTableCell = styled.td`
  padding: 5px;
  height: 1px;
  position: relative;
`

export const IconBtn = styled.button`
  color: ${({ theme }) => theme.text};
  background-color: transparent;
  border: none;
  font-size: 18px;
`

export const TagButton = styled.button`
  font-size: 12px;
  background-color: #0f2141;
  border: none;
  color: white;
  padding: 4px 6px;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #1d396b;
  }
`

export const StyledTabNavLink = styled(Link)`
  display: inline-block;
  color: unset;
  margin-right: 10px;
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: 3px solid transparent;
  white-space: nowrap;

  &:hover {
    border-bottom: 3px solid gray;
  }

  &[class*='active'] {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 3px solid #459aea;
    padding-bottom: 5px;
  }
`

export const StyledTabLink = styled(NavLink)`
  display: inline-block;
  color: unset;
  margin-right: 10px;
  text-decoration: none;
  padding-bottom: 5px;
  border-bottom: ${({ active }) =>
    active ? '3px solid #459aea' : '3px solid transparent'};
  white-space: nowrap;
  font-weight: ${({ active }) => (active ? 'bold' : '')};

  &:hover {
    border-bottom: 3px solid gray;
  }
`

export const StyledSelect = styled(Select).attrs(() => ({
  classNamePrefix: 'Select',
}))`
  .Select__control {
    background: ${({ theme }) => theme.navbar} !important;
    border: none;
  }

  .Select__input {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__menu {
    background: ${({ theme }) => theme.navbar} !important;
    box-shadow: ${({ theme }) =>
      `0 0 0 1px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%), 0 4px 11px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%)`} !important;
    z-index: 5;
  }

  .Select__single-value {
    color: white; // ${({ theme }) => theme.text} !important;
  }

  .Select__option {
    color:  white; // ${({ theme }) => theme.text} !important;
  }

  .Select__option:hover,
  .Select__option--is-focused {
    background: #35455c; // ${({ theme }) => theme.cardBackgroundHover} !important;
  }

  .Select__multi-value__remove {
    color: black;
  }
`

export const AsyncStyledSelect = styled(AsyncSelect).attrs(() => ({
  classNamePrefix: 'Select',
}))`
  .Select__control {
    background: ${({ theme }) => theme.body} !important;
  }

  .Select__input {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__menu {
    background: ${({ theme }) => theme.body} !important;
    box-shadow: ${({ theme }) =>
      `0 0 0 1px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%), 0 4px 11px hsl(0deg 0% ${
        theme.selectShadow || '0'
      }% / 10%)`} !important;
    z-index: 5;
  }

  .Select__single-value {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option {
    color: ${({ theme }) => theme.text} !important;
  }

  .Select__option:hover,
  .Select__option--is-focused {
    background: ${({ theme }) => theme.cardBackgroundHover} !important;
  }

  .Select__multi-value__remove {
    color: black;
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
`

export const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.text};
  text-decoration: none;
`

export const ButtonLink = styled(Link)`
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 15px;
  transition: 0.2s;
  text-decoration: none;
  background-color: #459aea;
  border: 2px solid #459aea;
  color: white;

  &:hover {
    background-color: #5ca7ed;
    border: 2px solid #5ca7ed;
  }
`
