import http from './http'

export const getParties = (query) => {
  return http.get('/api/parties', query)
}

export const createParties = (body) => {
  return http.post('/api/parties', body)
}

export const updateParties = (body) => {
  return http.put('/api/parties', body)
}

export const updatePartyMember = (body) => {
  return http.put('/api/parties/members', body)
}