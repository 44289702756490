import { Route, Routes } from 'react-router-dom'

import ReviewsC from '../../components/Reviews'
import CreateReview from '../../components/Reviews/CreateReview'

const Reviews = ({ user, isSelf }) => {
  return (
    <>
      <Routes>
        <Route index element={<ReviewsC user={user} isSelf={isSelf} />} />
        {!isSelf && (
          <Route path='create' element={<CreateReview user={user} />} />
        )}
      </Routes>
    </>
  )
}

export default Reviews
