import { useState, useEffect } from 'react'

import { getJobs } from '../../services/jobs'
import { sortStr } from '../../services/util'

import { StyledSelect } from '../Styled'

const JobSelect = ({
  category,
  onChange,
  onLoad,
  placeholder,
  tags,
  value,
  isClearable,
}) => {
  const [dbTags, setDbTags] = useState([])

  useEffect(() => {
    if (tags) {
      setDbTags(tags.sort((a, b) => sortStr(a, b, 'name')))
    } else {
      getJobs({ category }).then((res) => {
        if (res.error) return alert(res.error)
        setDbTags(res.data)
        if (onLoad) onLoad(res.data)
      })
    }
  }, [])

  const options = dbTags.map((tag) => {
    return {
      label: tag.name,
      value: tag.id,
      data: tag,
    }
  })

  return (
    <StyledSelect
      value={
        value
          ? options.find((option) => option.value + '' === value.id + '')
          : ''
      }
      onChange={onChange}
      placeholder={placeholder || 'Choose a role...'}
      options={options}
      isClearable={isClearable}
    />
  )
}

export default JobSelect
