import { useState } from 'react'

import { createTasks } from '../../services/tasks'

import JobSelect from '../../components/Jobs/JobSelect'
import Button from '../../components/Button'

const CreateTask = () => {
  const [newTask, setNewTask] = useState('')
  const [jobs, setJobs] = useState([])

  return (
    <div>
      <div className='input-row'>
        <div className='input-label'>New Task</div>
        <div>
          <input
            placeholder='Task name'
            value={newTask}
            onChange={(e) => {
              setNewTask(e.target.value)
            }}
          />
        </div>
      </div>
      <div className='input-row'>
        <div className='input-label'>Jobs</div>
        <JobSelect
          onChange={(option) => {
            jobs.push(option.data)
            setJobs([...jobs])
          }}
        />
      </div>
      <div className='input-row'>
        {jobs.map((job, idx) => {
          return (
            <div className='tasks-create-rm'>
              <button
                className='tasks-rm-btn'
                onClick={() => {
                  jobs.splice(idx, 1)
                  setJobs([...jobs])
                }}
              >
                X
              </button>
              <div>{job.name}</div>
            </div>
          )
        })}
      </div>
      <div className='input-row'>
        <Button
          onClick={() => {
            createTasks({
              name: newTask,
              jobs: jobs.map((job) => job.id),
            }).then((res) => {
              if (res.error) return alert(res.error)
              setNewTask('')
              setJobs([])
            })
          }}
        >
          Create
        </Button>
      </div>
    </div>
  )
}

export default CreateTask
