const PaliaDot = ({ style }) => (
  <svg
    width='15'
    height='22'
    viewBox='0 0 15 22'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path d='M4.28518 5.97937C5.35282 3.64026 8.67586 3.64026 9.7435 5.97937L11.1511 9.06329C12.0579 11.05 10.6058 13.309 8.42193 13.309H5.60675C3.42286 13.309 1.97079 11.05 2.87759 9.06329L4.28518 5.97937Z' />
    <path d='M9.74412 15.3282C8.67648 17.6674 5.35343 17.6674 4.2858 15.3282L2.8782 12.2443C1.9714 10.2576 3.42347 7.99865 5.60736 7.99865L8.42255 7.99865C10.6064 7.99865 12.0585 10.2576 11.1517 12.2443L9.74412 15.3282Z' />
  </svg>
)

export default PaliaDot
