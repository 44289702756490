import { useContext } from 'react'
import { FaTwitter, FaInstagram, FaYoutube, FaDiscord } from 'react-icons/fa'
import styled from 'styled-components'

import UserContext from '../../contexts/UserContext'

import { logOut } from '../../services/users'

import './style.css'

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: ${({ theme }) => theme.navbar};
  color: white;
  margin-top: 20px;
`

const Footer = () => {
  const { user } = useContext(UserContext)

  return (
    <StyledFooter className='container-fluid'>
      <div>
        <div className='footer-item footer-logo'>
          {/* {game.abbreviation === 'tft' ? <LogoLongTFT /> : <LogoLong />} */}
        </div>
        <div className='footer-item'>
          <a href='https://akrew.com/' target='blank'>
            Akrew
          </a>
        </div>
        <div className='footer-item footer-socials'>
          <a href='https://discord.gg/9ZrRaN8MrE' target='blank'>
            <FaDiscord />
          </a>
          <a href='http://twitter.com/akrewhq' target='blank'>
            <FaTwitter />
          </a>
          {/* <a href='https://www.youtube.com/c/Akrew' target='blank'>
            <FaYoutube />
          </a>
          <a href='https://www.instagram.com/akrewhq' target='blank'>
            <FaInstagram />
          </a> */}
        </div>
      </div>
      {/* <div>
        <div className='footer-item footer-header'>ABOUT</div>
        <div className='footer-item'>
          <a href='https://traderie.com/rocketleague?ref=rlegg' target='blank'>
            Trade Rocket League Items - Traderie
          </a>
        </div>
        <div className='footer-item'>
          <a href='mailto:esports@akrew.com' target='blank'>
            Contact Us
          </a>
        </div>
      </div> */}
      <div>
        {user && (
          <button
            className='logout-btn'
            onClick={() => {
              logOut()
              window.location.href = '/'
            }}
          >
            Log Out
          </button>
        )}
      </div>
    </StyledFooter>
  )
}

export default Footer
