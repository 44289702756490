import { useContext } from 'react'
import { NavLink, Link } from 'react-router-dom'
import styled from 'styled-components'
import { can } from 'rbac'

import GameContext from '../../contexts/GameContext'
import UserContext from '../../contexts/UserContext'
import { CDN_URL } from '../../services/constants'

import './style.css'

const NavbarContainer = styled.div`
  background-color: ${({ theme }) => theme.navbar};
`

const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 7px 0;

  a {
    display: inline-block;
    color: white;
    font-weight: bold;
    text-decoration: none;
  }
`

const StyledLogo = styled.div`
  width: 40px;
  margin-right: 10px;
`

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
  overflow: auto;

  @media only screen and (min-width: 600px) {
    margin-bottom: 0;
  }
`

const StyledNavLink = styled(NavLink)`
  display: block;
  padding: 14px 10px;
  border-bottom: 3px solid transparent;
  color: white;
  font-weight: bold;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    border-bottom: 3px solid #459aea;
  }

  &[class*='active'] {
    border-bottom: 3px solid #459aea;
  }

  @media only screen and (min-width: 600px) {
    font-size: inherit;
  }
`

const Toggle = styled.button`
  position: relative;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.gradient};
  width: 7em;
  height: 3.5em;
  border-radius: 30px;
  border: 2px solid ${({ theme }) => theme.toggleBorder};
  font-size: 0.5em;
  padding: 0.25em;
  overflow: hidden;
  cursor: pointer;

  img {
    max-width: 2.5em;
    height: auto;
    transition: all 0.3s linear;

    &:first-child {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translateY(0)' : 'translateY(100px)'};
    }

    &:nth-child(2) {
      transform: ${({ lightTheme }) =>
        lightTheme ? 'translateY(-100px)' : 'translateY(0)'};
    }
  }
`

const Navbar = (props) => {
  const { game } = useContext(GameContext)
  const { user } = useContext(UserContext)

  return (
    <NavbarContainer className='container-fluid'>
      <StyledNavbar>
        <StyledNav>
          <Link
            to='/'
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: 3,
              marginRight: 10,
            }}
          >
            <StyledLogo>
              <img
                src={`${CDN_URL}/${game.bucket}/logo.png`}
                alt={`${game.name} party`}
              />
            </StyledLogo>
            {game.name} Party
          </Link>
          <StyledNavLink to='/party/host'>Host</StyledNavLink>
          <StyledNavLink to='/parties'>Parties</StyledNavLink>
          {((user && can(user, 'ADMIN:PANEL')) || game.has('PARTY:TYPES')) && (
            <StyledNavLink to='/tasks'>Party Types</StyledNavLink>
          )}
          {user && can(user, 'ADMIN:PANEL') && (
            <>
              <StyledNavLink to='/jobs'>Jobs</StyledNavLink>
            </>
          )}
        </StyledNav>
        <StyledNav>
          {/* <Toggle
            onClick={props.toggleTheme}
            lightTheme={props.theme === 'light'}
          >
            <img
              src='https://cdn-icons-png.flaticon.com/512/869/869869.png'
              alt='Sun icon'
              title='Sun icon'
            />
            <img
              src='https://cdn-icons-png.flaticon.com/512/547/547433.png'
              alt='moon icon'
              title='Moon icon'
            />
          </Toggle> */}
          {/* {user && can(user, 'ADMIN:PANEL') && (
            <>
              &nbsp;&nbsp;<Link to='/admin'>ADMIN</Link>
            </>
          )} */}
          {user && (
            <>
              {user.generated && (
                <Link to={`/profile/settings`}>
                  <div className='claim-account'>Claim Your Account</div>
                </Link>
              )}
              <Link to={`/profile/${user.id}`}>{user.username}</Link>
            </>
          )}
          {!user && (
            <>
              <NavLink to='/login' className='btn-alt'>
                Log In
              </NavLink>
              &nbsp;&nbsp;
              <NavLink to='/signup' className='btn'>
                Sign Up
              </NavLink>
            </>
          )}
        </StyledNav>
      </StyledNavbar>
    </NavbarContainer>
  )
}

export default Navbar
