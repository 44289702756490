import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { BsCheckSquareFill, BsSquare } from 'react-icons/bs'
import styled from 'styled-components'

import UserContext from '../../contexts/UserContext'
import SocketContext from '../../contexts/SocketContext'
import {
  getParties,
  updateParties,
  updatePartyMember,
} from '../../services/parties'
import {
  setJWT,
  setUser,
  register,
  validateUsername,
} from '../../services/users'
import { getJobGroups } from '../../services/jobs'
import { CDN_URL } from '../../services/constants'

import Helmet from '../../components/Helmet'
import PaliaDot from '../../components/PaliaDot'
import Button from '../../components/Button'
import { StyledLink } from '../../components/Styled'
import RatingStars from '../../components/Reviews/RatingStars'
import { PartyCard, PartyUser } from '../../components/Parties'
import PartyProperties from '../../components/Parties/PartyProperties'

import './style.css'

const PartyPageTop = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.cardColor};
  border-radius: ${({ theme }) => theme.cardRadius};
  padding: 20px;
  color: ${({ theme }) => theme.cardTextColor};
  margin-bottom: 20px;
`

const Party = () => {
  const params = useParams()
  const [party, setParty] = useState({})
  const [username, setUsername] = useState('')

  const { user, setUser: setLocalUser } = useContext(UserContext)
  const socket = useContext(SocketContext)

  useEffect(() => {
    getParties({ id: params.id, jobs: true, properties: true }).then((res) => {
      setParty(res.data)
    })
  }, [params.id])

  const partyId = party.id
  useEffect(() => {
    function onPartyUpdate(partyMember, username) {
      const jobIdx = party.jobs.findIndex(
        (job) => job.party_member + '' === partyMember + ''
      )
      if (username === null) party.jobs[jobIdx].user_id = null
      party.jobs[jobIdx].username = username
      setParty({ ...party })
    }

    if (partyId) {
      socket.emit('party_join', params.id)
      socket.on('party_update', onPartyUpdate)
    }

    return () => {
      socket.off('party_update', onPartyUpdate)
    }
  }, [partyId])

  const isHost = user && party.user_id && party.user_id === user.id

  const groups = getJobGroups(party.jobs)

  let size = 0
  party.jobs?.forEach((job) => {
    if (job.username) size++
  })

  const jobNames = Object.keys(groups)

  return (
    <div className='page container-fluid'>
      <Helmet
        data={{
          name: party.name || party.task,
          description: party.description,
        }}
      />
      <PartyPageTop>
        <div>
          <StyledLink
            className='party-page-user'
            to={`/profile/${party.user_id}`}
          >
            {party.username}
            <span className='party-reviews'>
              <RatingStars rating={party.rating} size={20} /> ({party.reviews})
            </span>
          </StyledLink>
          <div className='party-title'>
            <div className='party-page-info'>
              <h1 className='party-page-name'>{party.name || party.task}</h1>
              {party.task && <h2 className='party-page-type'>{party.task}</h2>}
              {party.properties && (
                <PartyProperties properties={party.properties} />
              )}
            </div>
            {party.img && (
              <div>
                <img
                  className='party-page-img'
                  src={`${CDN_URL}/${party.img}`}
                  alt={party.name}
                />
              </div>
            )}
          </div>
          <p className='party-description'>{party.description}</p>
          <div>
            {party.jobs && (
              <h3 className='part-page-size'>
                <PaliaDot />
                {size}/{party.jobs.length} Spots
                <PaliaDot />
              </h3>
            )}
          </div>
        </div>
      </PartyPageTop>
      <div className='job-groups'>
        {jobNames.map((jobName) => {
          const group = groups[jobName]
          return (
            <div className='job-group'>
              <PartyCard>
                <PartyUser>
                  <PaliaDot />
                  {group[0].name}
                  <PaliaDot />
                </PartyUser>
                <div className='row party-jobs'>
                  {group.map((job, idx) => {
                    return (
                      <div className='party-job' key={job.party_member}>
                        <div className='job-img'>
                          {job.img && (
                            <img src={`${CDN_URL}/${job.img}`} alt={job.name} />
                          )}
                        </div>
                        <div className='party-join-username'>
                          {job.username ? (
                            job.username
                          ) : user ? (
                            '\xa0'
                          ) : (
                            <input
                              onChange={(e) => {
                                setUsername(e.target.value)
                              }}
                              placeholder='Username'
                            />
                          )}
                        </div>
                        <div>
                          {job.user_id && job.user_id + '' === user?.id ? (
                            <button
                              className='btn-check'
                              onClick={() => {
                                updatePartyMember({
                                  party: params.id,
                                  partyMember: job.party_member,
                                  username: null,
                                  user: null,
                                }).then((res) => {
                                  const idx = party.jobs.findIndex(
                                    (j) => j.party_member === job.party_member
                                  )
                                  party.jobs[idx] = {
                                    ...party.jobs[idx],
                                    username: null,
                                    user_id: null,
                                  }
                                  setParty({ ...party })
                                })
                              }}
                            >
                              <BsCheckSquareFill />
                            </button>
                          ) : isHost && job.username ? (
                            <button
                              className='btn-alt2'
                              onClick={() => {
                                updatePartyMember({
                                  party: params.id,
                                  partyMember: job.party_member,
                                  username: null,
                                  user: null,
                                }).then((res) => {
                                  const idx = party.jobs.findIndex(
                                    (j) => j.party_member === job.party_member
                                  )
                                  party.jobs[idx] = {
                                    ...party.jobs[idx],
                                    username: null,
                                    user_id: null,
                                  }
                                  setParty({ ...party })
                                })
                              }}
                            >
                              Kick
                            </button>
                          ) : (
                            !job.username && (
                              <button
                                className='btn-check'
                                onClick={async () => {
                                  const payload = {
                                    party: params.id,
                                    partyMember: job.party_member,
                                  }

                                  let tempUser = user
                                  if (user) {
                                    payload.user = user.id
                                    payload.username = user.username
                                  } else {
                                    if (!validateUsername(username))
                                      return alert('Invalid username')

                                    const userRes = await register({
                                      username,
                                    })
                                    if (userRes.error)
                                      return alert(userRes.error)
                                    setJWT(userRes.jwt)
                                    setUser(userRes.user)
                                    setLocalUser(userRes.user)
                                    payload.user = userRes.user.id
                                    tempUser = userRes.user
                                  }
                                  if (!payload.user)
                                    return alert('A username is required')

                                  const res = await updatePartyMember(payload)
                                  if (res.error) return alert(res.error)
                                  const idx = party.jobs.findIndex(
                                    (j) => j.party_member === job.party_member
                                  )
                                  party.jobs[idx].user_id = payload.user
                                  party.jobs[idx].username = tempUser.username
                                  setParty({ ...party })
                                }}
                              >
                                <BsSquare />
                              </button>
                            )
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </PartyCard>
            </div>
          )
        })}
      </div>
      {isHost && (
        <div className='party-btn-bar'>
          <Button
            onClick={() => {
              updateParties({ party: params.id, active: !party.active }).then(
                (res) => {
                  party.active = !party.active
                  setParty({ ...party })
                }
              )
            }}
          >
            {party.active ? 'Close' : 'Re-Open'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Party
