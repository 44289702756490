import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import { getProperties } from '../../services/properties'

import JobSelect from '../../components/Jobs/JobSelect'
import { StyledSelect } from '../../components/Styled'

const PartiesFilter = () => {
  const [properties, setProperties] = useState([])

  useEffect(() => {
    getProperties().then((res) => {
      setProperties(res.data)
    })
  }, [])

  const navigate = useNavigate()
  const location = useLocation()
  const query = qs.parse(location.search.substring(1))

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
      <span style={{ marginRight: 15 }}>Filters</span>
      <div style={{ marginRight: 10 }}>
        <JobSelect
          value={{ id: query.role }}
          onChange={(option) => {
            if (option) {
              query.role = option.value
            } else {
              delete query.role
            }
            navigate(`?${qs.stringify(query)}`)
          }}
          isClearable={true}
        />
      </div>
      {properties.map((property) => {
        const propKey = `prop_${property.id}`
        const options = property.options?.map((option) => {
          return {
            label: option.label || option.value,
            value: option.value,
          }
        })
        switch (property.type) {
          case 'bool':
            return (
              <div className='input-checkbox' style={{ marginRight: 10 }}>
                <input
                  type='checkbox'
                  checked={query[propKey] === 'true'}
                  onChange={(e) => {
                    if (e.target.checked) {
                      query[propKey] = e.target.checked
                    } else {
                      delete query[propKey]
                    }
                    navigate(`?${qs.stringify(query)}`)
                  }}
                />
                <div>{property.name}</div>
              </div>
            )
          default:
            return (
              <div className='input-checkbox' style={{ marginRight: 10 }}>
                <StyledSelect
                  value={options.find(
                    (option) => option.value + '' === query[propKey]
                  )}
                  onChange={(option) => {
                    if (option) {
                      query[propKey] = option.value
                    } else {
                      delete query[propKey]
                    }
                    navigate(`?${qs.stringify(query)}`)
                  }}
                  options={options}
                  isClearable
                  placeholder='Choose a Region...'
                />
              </div>
            )
        }
      })}
    </div>
  )
}

export default PartiesFilter
