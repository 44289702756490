import { useState } from 'react'
import Dropzone from 'react-dropzone'
import imageCompression from 'browser-image-compression'
import styled from 'styled-components'

const StyledDropzoneSection = styled.section`
  padding: 20px;
  background-color: #202224;
  border-radius: 10px;

  .dropzone {
    &:hover {
      cursor: pointer;
    }
  }
`

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })
}

const dataURItoBlob = (dataURI) => {
  let binary = atob(dataURI.split(',')[1])
  let array = []
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: 'image/png' })
}

const handleCompression = async (imageFile) => {
  const options = {
    maxSizeMB: 1, // 200KB
    maxWidthOrHeight: 3000,
    useWebWorker: true,
  }
  try {
    return await imageCompression(imageFile, options)
  } catch (err) {
    alert('Failed to upload')
  }
}

const FileUpload = ({
  message,
  handleUpload,
  noCompress,
  style,
  noWarning,
}) => {
  const [loading, setLoading] = useState(false)

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      acceptedFiles.forEach((file) => {
        getBase64(file).then(async (dataUrl) => {
          let blob = dataURItoBlob(dataUrl)
          if (!noCompress) {
            setLoading(true)
            blob = await handleCompression(blob)
            setLoading(false)
          }
          handleUpload(dataUrl, blob, file.name)
        })
      })
    } else {
      if (rejectedFiles[0].errors) {
        alert(rejectedFiles[0].errors[0].message)
      } else {
        alert('Invalid file format.')
      }
    }
  }

  return (
    <Dropzone onDrop={onDrop} maxSize={5000000} accept={'image/*'}>
      {({ getRootProps, getInputProps }) => (
        <div>
          <StyledDropzoneSection style={style}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className='dropzone'>
                {loading && (
                  <div className='lds-ring-1' style={{ marginRight: 5 }}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                )}
                {message || 'Click or Drag/Drop Image Here'}
              </div>
            </div>
          </StyledDropzoneSection>
          {!noWarning && (
            <div style={{ color: 'gray', fontSize: 12, marginTop: 5 }}>
              Max image size: 5MB
            </div>
          )}
        </div>
      )}
    </Dropzone>
  )
}

export default FileUpload
