export const sortStr = (a, b, key) => {
  const lowerA = a[key] ? a[key].toLowerCase() : a[key]
  const lowerB = b[key] ? b[key].toLowerCase() : b[key]
  if (lowerA < lowerB) {
    return -1
  } else if (lowerA > lowerB) {
    return 1
  } else {
    return 0
  }
}