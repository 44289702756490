import { createGlobalStyle } from 'styled-components'
import games from './games'

export const GlobalStyles = createGlobalStyle`
body {
  background: ${({ theme }) => theme.body};
  color: ${({ theme }) => theme.text};
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn,
.btn-alt,
.btn-alt2 {
  border-radius: ${({ theme }) => theme.btnBorderRadius};
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  padding: 7px 15px;
  text-decoration: none;
  transition: 0.2s;
}

.btn,
.btn-alt,
.btn-alt2:disabled {
  background-color: gray;
}

.btn {
  background-color: ${({ theme }) => theme.btnColor};
  border: ${({ theme }) => theme.btnBorder};
  color: white;
}

.btn:hover {
  background-color: ${({ theme }) => theme.btnHoverColor};
}

.btn-alt {
  background-color: ${({ theme }) => theme.btnAltColor};
  border: ${({ theme }) => theme.btnBorder};
  color: ${({ theme }) => theme.btnAltTextColor} !important; 
}

.btn-alt:hover {
  background-color: #d8b98d;
}

.btn-alt2 {
  background-color: transparent;
  border: 2px solid #459aea;
  color: #459aea;
}

.btn-alt2:hover {
  color: white;
  background-color: #459aea;
}

.btn-icon {
  font-size: 18px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.btn-icon:hover {
  color: #459aea;
}

.btn-link {
  background: none;
  color: inherit;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.btn-bar {
  display: flex;
  justify-content: space-between;
}

input,
textarea {
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  font-family: inherit;
  background-color: ${({ theme }) => theme.navbar};
  color: white;
}
`

// export const lightTheme = {
//   body: '#f7f7f7',
//   text: '#292e32',
// }

export const darkTheme = {
  body: 'linear-gradient(#394c6a, #437286)',
  // text: '#7a6e5f',
  text: 'black',
  navbar: '#2b394f',
  btnColor: '#594F42',
  btnBorder: '5px solid #8E8276',
  btnHoverColor: '#86755e',
  btnBorderRadius: '18px',
  btnAltColor: '#EACFA9',
  primary: '#e89f51',
  cardColor: '#efdfc9',
  cardBorder: '2px solid #c5b49f',
  cardRadius: '14px',
  cardLabelColor: '#594f42',
  cardLabelBorder: '5px #8e8276 solid',
  cardLabelRadius: '18px',
  ...games[process.env.REACT_APP_GAME],
}
